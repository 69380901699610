import React, { Fragment } from 'react';
import Name from './components/Name';
import Links from './components/Links';
import './App.css';

function App() {
  return (
    <div className="App">
      <Name>
      </Name>
      <Links>
      </Links>
    </div>
  );
}

export default App;
